import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import {BIconShare, BIconFacebook, BIconPinterest,
    BIconEnvelope, BIconWhatsapp, BIconChatDots, BIconMessenger} from 'bootstrap-icons-vue'
import { SFacebook, SEmail, SSms, SFacebookMessenger, SPinterest, SWhatsApp } from 'vue-socials/dist/vue-socials.es'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
    .use(router)
    .use(pinia)
    .component('BIconMessenger', BIconMessenger)
    .component('BIconChatDots', BIconChatDots)
    .component('BIconEnvelope', BIconEnvelope)
    .component('BIconWhatsapp', BIconWhatsapp)
    .component('BIconPinterest', BIconPinterest)
    .component('BIconShare',BIconShare)
    .component('BIconFacebook', BIconFacebook)
    .component('SFacebook', SFacebook)
    .component('SEmail', SEmail)
    .component('SFacebookMessenger', SFacebookMessenger)
    .component('SSms', SSms)
    .component('SPintrest', SPinterest)
    .component('SWhatsApp', SWhatsApp)
    .mount('#app')
