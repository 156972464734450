<template>
  <div>
    <body class="d-flex flex-column min-vh-100 w-screen overscroll-contain">
    <div v-if="$router.currentRoute.value.name === 'New User'">
      <home-header></home-header>
    </div>
    <div v-else-if="$router.currentRoute.value.name === 'promotion'">
      <header-promotion></header-promotion>
    </div>
    <div v-else>
      <header-component></header-component>
    </div>
    <div class="container all-row">
      <div class="row all-row">
        <div class="col-xl-3 col-md-3 col-sm-3 col-xs-3 all-row"></div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 all-row">

        <router-view/>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
    </body>
  </div>
</template>
<script>
import HeaderComponent from '@/views/HeaderComponent'
import FooterComponent from '@/views/FooterComponent'
import HeaderPromotion from '@/views/HeaderPromotion'
import HomeHeader from '@/views/HomeHeader'
export default {
  components: {
    HeaderPromotion,
    FooterComponent,
    HeaderComponent,
    HomeHeader,
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

html, body {
@import '../input.css';
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

div.all-row {
  margin: unset;
  padding: unset;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
