<template>
  <div>
    <div class="starburst-logo">
      <router-link to="/">
        <img alt="Starburst Logo" src="../assets/logo.png" class="starburst-logo">
      </router-link>
    </div>
  </div>
</template>

<script>
// import router from '@/router'

export default {
  name: 'HeaderComponent',

}
</script>

<style scoped>
img.starburst-logo {
  height: 65pt;
  padding-top: 10pt;
  text-align: center;
  display: inherit;
}

div.starburst-logo {
  background-color: #893594;
  text-align: center;
  height: 75pt

}

img.partner-logo {
  position: center;
  vertical-align: bottom;
  height: 75pt;
  text-align: center;
}

div.partner-logo {
  position: absolute;
  float: right;
  width: 33%;

}

.bm-menu {
  background: white !important;
}

</style>
