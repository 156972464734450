<template>
  <div>
    <!--    <div v-if="$route.path !== '/signup'">-->
<!--    <Slide :closeOnNavigation="true">-->
<!--      <router-link to="/">Home</router-link>-->
<!--      <router-link to="/card">Card</router-link>-->
<!--      <router-link to="/promotions">Promotions</router-link>-->
<!--      <router-link to="/about">About us</router-link>-->
<!--    </Slide>-->
    <!--    </div>-->
    <div class="row">
      <div class="col-7 starburst-logo">
        <router-link to="/">
          <img alt="Starburst Logo" src="../assets/logo.png" class="starburst-logo">
        </router-link>
      </div>
      <div class="col-5 partner-logo">

        <img :src="deal.partnerImage" alt="Partner" class="partner-logo">
      </div>
    </div>
  </div>
</template>

<script>
import { useDeals } from '@/stores/useDeals'
import { useRoute } from 'vue-router'

export default {
  name: 'HeaderPromotion',
  setup () {
    const route = useRoute()
    const dealId = parseInt(route.params.id)
    const deals = useDeals()
    const deal = deals.getDealById(dealId)
    return { deal }
  }
}
</script>

<style scoped>
img.starburst-logo {
  height: 65pt;
  /*padding-top: 10pt;*/
  text-align: right;
  display: inherit;
}

div.starburst-logo {
  background-color: #893594;
  text-align: right;
  height: 75pt

}

img.partner-logo {
  padding-top: 5pt;
  position: center;
  vertical-align: bottom;
  height: 65pt;
  text-align: center;
  display: inline;
}

div.partner-logo {
  height: 75pt;
  text-align: center;

}

.bm-menu {
  background: white !important;
}

</style>
