<template>
<div class="strburst-footer mt-auto">
    <p class="strburst-copy">&copy;SofServ</p>
</div>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
.strburst-footer{
  height: 30pt;
  background: #FDB913;
  margin-top: 10pt;
  text-align: center;
}
.strburst-copy{
  font-size: 8pt;
  padding-top: 10pt;
}
</style>
